import i18n from "i18next";
import { reactI18nextModule } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(reactI18nextModule)
.use(LanguageDetector)
.use(reactI18nextModule)
.init({
  fallbackLng: "tj",
  detection: {
    order: ['localStorage', 'navigator'],
    caches: ['localStorage'],
  },
  resources: {
    en: {
      translations: require("./locales/en/translations.json"),
    },
    ru: {
      translations: require("./locales/ru/translations.json"),
    },
    tj: {
      translations: require("./locales/tj/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});
i18n.languages = ["en", "ru", "tj"];

export default i18n;
