import { Routes, Route, useParams } from "react-router-dom";
import React, { Suspense } from "react";
import PrivateRoutes from "./PrivateRoutes.js";

const MainPage = React.lazy(() =>
  import("./components/pages/main-page/main.page")
);

const ProjectsPage = React.lazy(() =>
  import("./components/pages/projects-page/projects.page")
);

const MapPage = React.lazy(() =>
  import("./components/pages/map-page/map.page")
);

const ProjectViewPage = React.lazy(() =>
  import("./components/pages/project.view-page/project.view-page")
);

const ReportsPage = React.lazy(() =>
  import("./components/pages/reports-page/reports.page")
);

const ContactsPage = React.lazy(() =>
  import("./components/pages/contact-page/contact-page")
);

const AdminPage = React.lazy(() =>
  import("./components/pages/admin-page/admin-page")
);

const AdminMainPage = React.lazy(() =>
  import("./components/pages/admin-page/pages/admin.main-page")
);

const AdminProjectsPage = React.lazy(() =>
  import("./components/pages/admin-page/pages/projects-page/projects.page")
);

const AdminProjectViewPage = React.lazy(() =>
  import(
    "./components/pages/admin-page/pages/project.view-page/project.view-page"
  )
);

const AdminReportsPage = React.lazy(() =>
  import("./components/pages/admin-page/pages/reports-page/reports.page")
);

const AdminProjectCreatePage = React.lazy(() =>
  import(
    "./components/pages/admin-page/pages/project.create-page/project.create-page"
  )
);

const AdminUploadDocumentPage = React.lazy(() =>
  import(
    "./components/pages/admin-page/pages/upload.document-page/upload.document-page"
  )
);

const AdminUsersSeePage = React.lazy(() =>
  import("./components/pages/admin-page/pages/users.see.page/users.see.page")
);

const AdminUsersCreatePage = React.lazy(() =>
  import(
    "./components/pages/admin-page/pages/users.create.page/users.create.page"
  )
);

const AdminKnowledgeHubPage = React.lazy(() =>
  import(
    "./components/pages/admin-page/pages/knowledge.hub-page/knowledge.hub.page"
  )
);

const KnowledgeHubPage = React.lazy(() =>
  import("./components/pages/knowledge.hub-page/knowledge.hub.page")
);

export default function App() {
  return (
    <Suspense fallback={<span> Loading...</span>}>
      <div className="App">
        <Routes>
          <Route path="*" element={<MainPage />} />
          <Route path="/" element={<MainPage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/project/:id" element={<ProjectViewPage />} />
          <Route path="/map" element={<MapPage />} />
          <Route path="/knowledge-hub" element={<KnowledgeHubPage />} />
          <Route path="/reports" element={<ReportsPage />} />
          <Route path="/contacts" element={<ContactsPage />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/admin/main" element={<AdminMainPage />} />
            <Route path="/admin/projects" element={<AdminProjectsPage />} />
            <Route
              path="/admin/project/:id"
              element={<AdminProjectViewPage />}
            />
            <Route path="/admin/reports" element={<AdminReportsPage />} />
            <Route
              path="/admin/create-project"
              element={<AdminProjectCreatePage />}
            />
            <Route
              path="/admin/upload-document"
              element={<AdminUploadDocumentPage />}
            />
            <Route path="/admin/users-see" element={<AdminUsersSeePage />} />
            <Route
              path="/admin/users-create"
              element={<AdminUsersCreatePage />}
            />
            <Route
              path="/admin/knowledge-hub"
              element={<AdminKnowledgeHubPage />}
            />
          </Route>
        </Routes>
      </div>
    </Suspense>
  );
}
